import { configureStore } from "@reduxjs/toolkit";
import ChatStore from "./ChatStore";

// Configure the store add the settings slice too

const store = configureStore({
  reducer: ChatStore.reducer,
});

export default store;
