import axios from "axios";

// create an axios instance based on the environment

let baseURL;

if (process.env.NODE_ENV === "development") {
  baseURL = "http://localhost:8081/api";
} else {
  baseURL = "/api";
}

// Set the base URL dynamically using environment variables
const api = axios.create({
  baseURL,
  timeout: 10000, // 10-second timeout
});

// Interceptor to attach headers to each request
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Interceptor to handle response errors globally
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          localStorage.removeItem("token");
          localStorage.removeItem("username");
          localStorage.removeItem("user");
          window.location.href = "/login";
          break;
        case 403:
          alert("You do not have permission to perform this action.");
          break;
        case 500:
          alert("Server error. Please try again later.");
          break;
        default:
          alert("An unexpected error occurred.");
      }
    }
    return Promise.reject(error);
  }
);

export default api;
