import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import api from "../../api";
import "./PasswordManagerPage.scss";
import PasswordModal from "./PasswordsModal";

interface Account {
  accountName: string;
  password: string;
}

interface Service {
  serviceName: string;
}

const PasswordManagerPage: React.FC = () => {
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [showPasswords, setShowPasswords] = useState<boolean[]>([]);
  const [selectedAccount, setSelectedAccount] = useState<Account | null>(null);
  const [services, setServices] = useState<Service[]>([]);
  const [selectedService, setSelectedService] = useState<string>("");
  const [newServiceName, setNewServiceName] = useState<string>("");

  useEffect(() => {
    fetchServices();
  }, []);

  useEffect(() => {
    if (selectedService) fetchPasswords(selectedService);
  }, [selectedService]);

  const fetchServices = async (service?: string) => {
    try {
      const headers = {
        "Content-Type": "application/json",
      };
      const response: any = await api.get<Service[]>("/passwords/services", {
        headers,
      });
      setServices(response.data.serviceNames || []);
      if (response.data?.serviceNames.length > 0) {
        setSelectedService(
          service ?? response.data.serviceNames[0].serviceName
        );
      }
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };

  const fetchPasswords = async (service: string) => {
    try {
      const headers = {
        "Content-Type": "application/json",
      };
      const response: any = await api.post<Account[]>(
        "/passwords/passwords/all",
        { service },
        { headers }
      );
      setAccounts(response.data.accounts);
      setShowPasswords(Array(response.data.accounts.length).fill(false));
    } catch (error) {
      console.error("Error fetching passwords:", error);
    }
  };

  const handleTogglePasswordVisibility = (index: number) => {
    const newShowPasswords = [...showPasswords];
    newShowPasswords[index] = !newShowPasswords[index];
    setShowPasswords(newShowPasswords);
  };

  const handleCopyToClipboard = (password: string) => {
    navigator.clipboard
      .writeText(password)
      .then(() => {
        toast("Password copied to clipboard");
      })
      .catch((error) => {
        console.error("Error copying password to clipboard:", error);
      });
  };

  const handleEditPassword = (account: Account) => {
    setSelectedAccount(account);
    // Open a modal or form for editing the password
  };

  const handleSavePassword = async (editPassword: string) => {
    try {
      if (selectedAccount?.accountName) {
        const newAccount = {
          account: selectedAccount?.accountName,
          password: editPassword,
          service: selectedService,
        };

        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        };
        await api.put("/passwords/passwords", newAccount, { headers });
        setSelectedAccount(null);

        await fetchPasswords(selectedService);
      }
    } catch (error) {
      setSelectedAccount(null);
    }
  };

  const handleAddEntry = async () => {
    try {
      if (newServiceName === "") return toast("Please enter a service name");
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };
      await api.post(
        "/passwords/passwords",
        {
          service: newServiceName,
        },
        { headers }
      );
      setNewServiceName("");
      await fetchServices(newServiceName);
      await fetchPasswords(newServiceName);
    } catch (error) {
      console.error("Error adding new service:", error);
    }
  };
  const handleDeleteServiceEntry = async () => {
    try {
      if (selectedService === "") {
        toast("Please select a service to delete.");
        return;
      }
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };
      await api.post(
        "/passwords/services/delete",
        {
          service: selectedService,
        },
        { headers }
      );
      await fetchServices();
    } catch (error: any) {
      if (error.response.data.error) toast(error.response.data.error);
      console.error("Error adding new service:", error);
    }
  };

  const handleDeleteEntry = async (props: any) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };
      await api.post(
        "/passwords/passwords/delete",
        {
          service: props.service,
          account: props.account,
        },
        { headers }
      );
      await fetchPasswords(props.service);
    } catch (error) {
      console.error("Error adding new service:", error);
    }
  };

  return (
    <div className="password-manager-container">
      <div className="container">
        <p>Personal Passwords</p>
        <input
          style={{ padding: "10px", borderRadius: "5px" }}
          type="text"
          value={newServiceName}
          onChange={(e) => setNewServiceName(e.target.value)}
          placeholder="Enter new service name"
        />
        <button onClick={handleAddEntry}>Add Service</button>
        <button onClick={handleDeleteServiceEntry}>Delete Service</button>

        <select
          value={selectedService}
          onChange={(e) => setSelectedService(e.target.value)}
        >
          {services.map((service: Service, index: number) => (
            <option key={index} value={service.serviceName}>
              {service.serviceName}
            </option>
          ))}
        </select>
      </div>
      <table className="passwords-table">
        <thead>
          <tr>
            <th>Account</th>
            <th>Password</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {accounts.map((account: Account, index: number) => (
            <tr key={index}>
              <td>{account.accountName}</td>
              <td>
                {showPasswords[index] ? (
                  account.password
                ) : (
                  <button onClick={() => handleTogglePasswordVisibility(index)}>
                    Show Password
                  </button>
                )}
              </td>
              <td className="buttons">
                <button onClick={() => handleCopyToClipboard(account.password)}>
                  Copy
                </button>
                <button onClick={() => handleEditPassword(account)}>
                  Edit
                </button>{" "}
                <button
                  onClick={() =>
                    handleDeleteEntry({
                      account: account.accountName,
                      service: selectedService,
                    })
                  }
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <GenNewPassword
        currentService={selectedService}
        fetchPasswords={fetchPasswords}
      />
      <PasswordModal
        open={!!selectedAccount}
        currentService={selectedService}
        fetchPasswords={fetchPasswords}
        mode="edit"
        accountName={selectedAccount?.accountName || ""}
        initialPassword={selectedAccount?.password || ""}
        onClose={() => setSelectedAccount(null)}
        onSave={handleSavePassword}
      />
    </div>
  );
};

const GenNewPassword = (props: {
  currentService: string;
  fetchPasswords: Function;
}) => {
  const [showModal, setShowModal] = useState(false);
  const [newAccountName, setNewAccountName] = useState("");
  const [generatedPassword, setGeneratedPassword] = useState("");

  useEffect(() => {
    return () => {
      setNewAccountName("");
      setGeneratedPassword("");
    };
  }, []);
  const handleGeneratePassword = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };
      const response: any = await api.post(
        "/passwords/passwords/generate",
        {
          service: props.currentService,
          account: newAccountName,
        },
        { headers }
      );
      if (response.data.status === 200) {
        toast(response.data.message);
        setNewAccountName("");
        props.fetchPasswords(props.currentService);
      }
      setGeneratedPassword(response.message);
    } catch (error) {
      console.error("Error generating password:", error);
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
    setNewAccountName("");
  };

  const handleModalSubmit = () => {
    handleGeneratePassword();
    setShowModal(false);
  };

  return (
    <div>
      <button onClick={() => setShowModal(true)}>Generate New Password</button>
      <PasswordModal
        open={showModal}
        currentService={props.currentService}
        fetchPasswords={props.fetchPasswords}
        mode="generate"
        accountName={newAccountName}
        initialPassword={generatedPassword}
        onClose={handleModalClose}
        onSave={handleModalSubmit}
      />
    </div>
  );
};

export default PasswordManagerPage;
