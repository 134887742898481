import React from "react";

interface BasicDateTimePickerProps {
  value: Date | null;
  onChange: (date: Date | null) => void;
}

const BasicDateTimePicker: React.FC<BasicDateTimePickerProps> = ({
  value,
  onChange,
}) => {
  // Format date to "YYYY-MM-DDTHH:MM" for datetime-local
  const formatDateTimeLocal = (date: Date) => {
    const offset = date.getTimezoneOffset() * 60000; // Offset in milliseconds
    const localISOTime = new Date(date.getTime() - offset)
      .toISOString()
      .slice(0, 16);
    return localISOTime;
  };

  return (
    <input
      className="form-control"
      type="datetime-local"
      value={value ? formatDateTimeLocal(value) : ""}
      onChange={(e) =>
        onChange(e.target.value ? new Date(e.target.value) : null)
      }
    />
  );
};

export default BasicDateTimePicker;
