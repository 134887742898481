import React, { useEffect, useState, useCallback } from "react";
import { Button, CircularProgress } from "@mui/material";
import api from "../../api";
import "./portfolio.scss";
import { GitHub, Launch } from "@mui/icons-material";

enum Status {
  LOADING = "loading",
  SUCCESS = "success",
  ERROR = "error",
}

interface Language {
  name: string;
  percentage: string;
}

interface Repository {
  id: number;
  name: string;
  description: string;
  homepage?: string;
  html_url: string;
  languages: Language[];
}

const Portfolio: React.FC = () => {
  const [data, setData] = useState<Repository[]>([]);
  const [status, setStatus] = useState<Status>(Status.LOADING);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get("/repos");
        setData(response.data || []);
        setStatus(Status.SUCCESS);
      } catch (error: any) {
        setError(error.response?.data?.message || "An error occurred");
        setStatus(Status.ERROR);
      }
    };

    fetchData();
  }, []);

  const getLanguageColor = useCallback((language: string) => {
    const colors: Record<string, string> = {
      JavaScript: "#f1e05a",
      TypeScript: "#3178c6",
      Python: "#3572A5",
      CSS: "#563d7c",
      HTML: "#e34c26",
      Java: "#b07219",
      C: "#555555",
      "C++": "#f34b7d",
    };
    return colors[language] || "#ccc";
  }, []);

  return (
    <div className="projects-container">
      {status === Status.LOADING && (
        <div className="loading">
          <CircularProgress />
          <p>Loading...</p>
        </div>
      )}
      {status === Status.ERROR && <p className="error">Error: {error}</p>}
      {status === Status.SUCCESS && data.length === 0 && (
        <p className="empty">No repositories available</p>
      )}
      <div className="projects-grid">
        {data.map((repo) => (
          <div className="project-card" key={repo.id}>
            <h3 className="project-title">{repo.name}</h3>
            <p className="project-description">
              {repo.description || "No description available"}
            </p>
            <div className="language-stats">
              {repo.languages.map((lang, index) => (
                <div key={index} className="language-bar">
                  <span>
                    {lang.name}: {lang.percentage}%
                  </span>
                  <div
                    className="language-fill"
                    style={{
                      width: `${lang.percentage}%`,
                      backgroundColor: getLanguageColor(lang.name),
                    }}
                  ></div>
                </div>
              ))}
            </div>

            <div className="buttons-container">
              {repo.homepage && (
                <Button
                  href={repo.homepage}
                  className="button live-button"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label={`View live preview of ${repo.name}`}
                >
                  <Launch className="button-icon" />
                  Live Preview
                </Button>
              )}
              <Button
                href={repo.html_url}
                className="button source-button"
                target="_blank"
                rel="noopener noreferrer"
                aria-label={`View source code of ${repo.name}`}
              >
                <GitHub className="button-icon" />
                Source Code
              </Button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Portfolio;
