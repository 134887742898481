import { useEffect, useRef } from "react";
import { addMessage } from "../redux/ChatStore";
import store from "../redux";

export interface Message {
  content: string;
  channelId: string;
  sender: string;
}

const WebSocketClient = () => {
  const socketRef = useRef<WebSocket | null>(null);
  const reconnectAttempts = useRef(0);
  const reconnectTimeout = useRef<NodeJS.Timeout | null>(null);
  const messageQueue = useRef<Message[]>([]);

  const connectSocket = () => {
    const protocol = window.location.protocol === "https:" ? "wss" : "ws";
    const user = localStorage.getItem("user");
    if (!user) {
      console.error("No user found in localStorage");
      return;
    }

    const fullUrl =
      process.env.NODE_ENV === "development"
        ? `${protocol}://localhost:8081/?user=${encodeURIComponent(user)}`
        : `${protocol}://${
            process.env.REACT_APP_Domain || window.location.hostname
          }/?user=${encodeURIComponent(user)}`;

    console.log("Connecting to WebSocket:", fullUrl);
    const socket = new WebSocket(fullUrl);
    socketRef.current = socket;

    socket.onopen = () => {
      console.log("✅ WebSocket connected");
      reconnectAttempts.current = 0; // Reset attempts on success

      // Send any queued messages
      while (messageQueue.current.length > 0) {
        const queuedMessage = messageQueue.current.shift();
        if (queuedMessage) {
          socket.send(JSON.stringify(queuedMessage));
        }
      }
    };

    socket.onmessage = (event) => {
      try {
        const message = JSON.parse(event.data);
        if (message.content && message.channelId && message.sender) {
          store.dispatch(addMessage(message));
        } else {
          console.warn("⚠️ Invalid message structure:", message);
        }
      } catch (error) {
        console.error("❌ Failed to parse WebSocket message:", error);
      }
    };

    socket.onclose = (event) => {
      console.warn("⚠️ WebSocket closed:", event.reason || "Unknown reason");

      if (reconnectAttempts.current < 5) {
        const delay = Math.min(3000 * 2 ** reconnectAttempts.current, 30000); // Exponential backoff, max 30s
        console.log(`🔄 Reconnecting in ${delay / 1000}s...`);
        reconnectTimeout.current = setTimeout(connectSocket, delay);
        reconnectAttempts.current += 1;
      } else {
        console.error("❌ Maximum WebSocket reconnection attempts reached.");
      }
    };

    socket.onerror = (error) => {
      console.error("❌ WebSocket error:", error);
      socket.close();
    };
  };

  useEffect(() => {
    connectSocket();
    return () => {
      if (socketRef.current) {
        socketRef.current.close();
      }
      if (reconnectTimeout.current) {
        clearTimeout(reconnectTimeout.current);
      }
    };
  }, []);

  const sendMessage = (message: Message) => {
    const socket = socketRef.current;
    if (socket && socket.readyState === WebSocket.OPEN) {
      socket.send(JSON.stringify(message));
    } else {
      console.warn("⚠️ WebSocket not open, queuing message...");
      messageQueue.current.push(message);
      connectSocket();
    }
  };

  return { sendMessage };
};

export default WebSocketClient;
