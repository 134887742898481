import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../api";
import store from "../../redux";
import { setIsConnected, setUserName } from "../../redux/ChatStore";
import videoSrc from "../../video/AdobeStock_789998454.mov";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: { main: "#90caf9" },
    secondary: { main: "#f48fb1" },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `body { background-color: #212121; color: #fff; }`,
    },
  },
});

const LandingPage = () => {
  const navigate = useNavigate();
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [isGuestsEnabled, setIsGuestsEnabled] = useState(false);

  useEffect(() => {
    localStorage.removeItem("token");
    localStorage.removeItem("username");

    const initialState = store.getState();
    setIsGuestsEnabled(!!initialState.isAllowGuests);

    const unsubscribe = store.subscribe(() => {
      const state = store.getState();
      setIsGuestsEnabled(!!state.isAllowGuests);
    });

    return () => unsubscribe();
  }, []);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    try {
      const response = await api.post("/auth/login", {
        identifier: data.get("identifier"),
        password: data.get("password"),
        rememberMe: data.get("remember"),
      });
      if (response.status === 200) {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("user", JSON.stringify(response.data.user));
        store.dispatch(
          setUserName(response.data.user.username || response.data.user.id)
        );
        store.dispatch(setIsConnected(true));
        navigate("/chat");
      }
    } catch (error) {
      toast.error("Login failed. Please check your credentials.");
    }
  };

  const handleGuest = async (event: any) => {
    event.preventDefault();
    try {
      const response = await api.post("/auth/guest");
      if (response.status === 200) {
        const { token, user } = response.data;
        localStorage.setItem("token", token);
        localStorage.setItem("user", JSON.stringify(user));
        store.dispatch(setUserName(user.id));
        store.dispatch(setIsConnected(true));
        navigate("/chat");
      } else {
        toast.error("Unable to create guest session. Please try again.");
      }
    } catch (error) {
      console.error("Guest login failed:", error);
      toast.error("An error occurred while logging in as a guest.");
    }
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <div className="landing-container" role="main">
        <video className="video-background" autoPlay loop muted>
          <source src={videoSrc} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <Card
          variant="outlined"
          sx={{
            maxWidth: "450px",
            padding: "2rem",
            margin: "auto",
            backgroundColor: "rgba(33, 33, 33, 0.7)",
            color: "#fff",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <Typography component="h1" variant="h4" sx={{ textAlign: "center" }}>
            Sign in
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ display: "flex", flexDirection: "column", gap: 2 }}
          >
            <FormControl>
              <FormLabel htmlFor="identifier">Username or Email</FormLabel>
              <TextField
                id="identifier"
                name="identifier"
                required
                fullWidth
                variant="outlined"
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="password">Password</FormLabel>
              <TextField
                id="password"
                name="password"
                type="password"
                required
                fullWidth
                variant="outlined"
              />
            </FormControl>
            <FormControlLabel
              control={<Checkbox name="remember" color="primary" />}
              label="Remember me"
            />
            <Button type="submit" fullWidth variant="contained">
              Sign in
            </Button>
          </Box>
          <Divider>or</Divider>
          {isGuestsEnabled && (
            <Button
              variant="contained"
              style={{ width: "100%" }}
              color="secondary"
              onClick={handleGuest}
            >
              Continue as Guest
            </Button>
          )}
          {/* <Typography textAlign="center">
            Don't have an account? <Link href="/signup">Sign up</Link>
          </Typography> */}
        </Card>
      </div>
    </ThemeProvider>
  );
};

export default LandingPage;
