import { FormControlLabel, Switch } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import { useCallback, useEffect, useState } from "react";
import "./settings.scss";
import api from "../../api";
import { toast } from "react-toastify";
import store from "../../redux";
import { setMute } from "../../redux/ChatStore";

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    "&::before, &::after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    "&::before": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    "&::after": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
  },
}));

const SettingsPage = () => {
  const [settings, setSettings] = useState({
    allowGuests: false,
    tokenExpiration: "2h",
    paCommands: false,
    showCommandsOutput: true,
    _id: "", // Store the ID from the backend
  });

  // Fetch site settings on component mount
  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await api.get("/settings");
        setSettings(response.data);
        console.log("Fetched settings:", response.data);
      } catch (error) {
        console.error("Error fetching settings:", error);
        toast.error("Failed to load site settings.");
      }
    };

    fetchSettings();
  }, []);

  // Update settings in the database
  const updateSettings = useCallback(async () => {
    try {
      if (!settings._id) return;
      const response = await api.put(`/settings/${settings._id}`, settings);
      console.log("Updated settings:", response.data);
      toast.success("Settings updated successfully!");
    } catch (error) {
      console.error("Error updating settings:", error);
      toast.error("Failed to update site settings.");
    }
  }, [settings]);

  // Debounce updates to minimize frequent API calls
  useEffect(() => {
    const timeout = setTimeout(() => {
      updateSettings();
    }, 500); // 500ms debounce
    return () => clearTimeout(timeout);
  }, [settings, updateSettings]);

  // Generalized input change handler
  const handleInputChange = (key: any) => (event: any) => {
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    setSettings((prev) => ({ ...prev, [key]: value }));
  };

  return (
    <div className="settings-container">
      <h2 className="main-title">Settings</h2>
      <hr />

      <h3 className="sub-title">Site Settings</h3>

      <div className="setting-item">
        <label className="setting-item-label">
          Token Expiration (e.g., 2h):
        </label>
        <input
          type="text"
          value={settings.tokenExpiration}
          onChange={handleInputChange("tokenExpiration")}
          placeholder="Enter token expiration (e.g., 2h, 30m)"
        />
      </div>

      <div className="setting-item">
        <FormControlLabel
          labelPlacement="start"
          control={
            <Android12Switch
              checked={settings.allowGuests}
              onChange={handleInputChange("allowGuests")}
            />
          }
          label="Allow Guests to the site:"
        />
      </div>

      <div className="setting-item">
        <FormControlLabel
          labelPlacement="start"
          control={
            <Android12Switch
              checked={settings.paCommands}
              onChange={handleInputChange("paCommands")}
            />
          }
          label="Enable Personal Assistant Commands:"
        />
      </div>

      <div className="setting-item">
        <FormControlLabel
          labelPlacement="start"
          control={
            <Android12Switch
              checked={settings.showCommandsOutput}
              onChange={handleInputChange("showCommandsOutput")}
            />
          }
          label="Show Personal Assistant Command Output:"
        />
      </div>

      <div>
        <h3 className="sub-title">Archive Options</h3>
        <button onClick={handleArchiveAll}>Archive All</button>
        <button onClick={handleDeleteAll}>Delete All</button>
      </div>
    </div>
  );
};
export const handleArchiveAll = async () => {
  try {
    const user: any = JSON.parse(localStorage.getItem("user") || "");

    if (!user) {
      console.error("User not found in localStorage.");
      toast.error("Failed to archive all tasks.");
      return;
    }

    const response = await api.post("/chat/archive/all", {
      userId: user.id,
    });
    console.log("Archived all tasks:", response.data);
    toast.success("All tasks archived successfully!");
  } catch (error) {
    console.error("Error archiving tasks:", error);
    toast.error("Failed to archive all tasks.");
  }
};

export const handleDeleteAll = async () => {
  try {
    const user: any = JSON.parse(localStorage.getItem("user") || "");

    if (!user) {
      console.error("User not found in localStorage.");
      toast.error("Failed to archive all tasks.");
      return;
    }
    await api.post("/chat/delete/all", {
      userId: user.id,
    });
    toast.success("All tasks deleted successfully!");
  } catch (error) {
    toast.error("Failed to delete all tasks.");
  }
};
export const handleMute = async (mute: boolean) => {
  try {
    const user: any = JSON.parse(localStorage.getItem("user") || "");

    if (!user) {
      toast.error("Failed to archive all tasks.");
      return;
    }
    await api.post("/settings/mute/", {
      mute,
    });
    toast.success("Muted successfully!");
    store.dispatch(setMute(mute));
  } catch (error) {
    toast.error("Failed to Muted.");
  }
};
export default SettingsPage;
