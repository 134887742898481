import { Tooltip } from "@material-ui/core";
import {
  Archive,
  ContentCopy,
  Delete,
  Mic,
  Settings,
  VolumeOff,
  VolumeUp,
} from "@mui/icons-material";
import SendIcon from "@mui/icons-material/Send";
import {
  IconButton,
  InputAdornment,
  MenuItem,
  Popover,
  TextField,
  Typography,
} from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import moment from "moment";
import { MouseEvent, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { toast } from "react-toastify";
import api from "../../api";
import store from "../../redux";
import { setMessages } from "../../redux/ChatStore";
import WebSocketClient, { Message } from "../../sockets/socket";
import {
  handleArchiveAll,
  handleDeleteAll,
  handleMute,
} from "../settings/Settings";
import "./Chat.scss";

interface ChatSidebarProps {
  currentChannel: string;
  handleChange: (event: { target: { value: string } }) => void;
  openConvos: { _id: string; channelId: string }[];
  handleCreateChannel: () => void;
}

const ChatSidebar: React.FC<ChatSidebarProps> = ({
  currentChannel,
  handleChange,
  openConvos,
  handleCreateChannel,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isMute, setIsMute] = useState(false);

  useEffect(() => {
    // Subscribe to Redux store changes

    const unsubscribe = store.subscribe(() => {
      const state: any = store.getState();
      // Update local state from Redux state
      setIsMute(state.isMute);
    });

    // Cleanup subscription on component unmount
    return () => {
      unsubscribe();
    };
  }, []); // Empty dependency array ensures this runs only once

  const handleSettingsClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="chat-sidebar">
      <Typography variant="h6" className="sidebar-title">
        Channels
      </Typography>

      <List className="channel-list">
        {openConvos.map((convo) => (
          <ListItem
            key={convo._id}
            button
            selected={currentChannel === convo.channelId}
            onClick={() => handleChange({ target: { value: convo.channelId } })}
            className={
              currentChannel === convo.channelId ? "selected-channel" : ""
            }
            sx={{
              color: "white",
              "&.selected-channel": {
                backgroundColor: "#333",
                color: "#fff",
              },
              "&:hover": {
                backgroundColor: "#444",
              },
            }}
          >
            <ListItemText primary={convo.channelId} />
          </ListItem>
        ))}

        <ListItem
          key="add-channel"
          button
          onClick={handleCreateChannel}
          sx={{
            color: "white",
            "&:hover": {
              backgroundColor: "#444",
            },
          }}
        >
          <ListItemText primary="Start a new convo" />
        </ListItem>
      </List>

      <div className="settings-section">
        <IconButton
          onClick={handleSettingsClick}
          sx={{ color: "white", display: "flex", gap: "10px" }}
        >
          <Typography variant="body1" className="settings-text">
            Chat Settings
          </Typography>
          <Settings />
        </IconButton>

        {/* Dark Mode Popover */}
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          sx={{
            "& .MuiPaper-root": {
              padding: "10px",
              backgroundColor: "#222",
              color: "white",
            },
          }}
        >
          <MenuItem
            onClick={async () => {
              await handleMute(!isMute);
              handleClose();
            }}
            sx={{
              color: "white",

              "&:hover": { backgroundColor: "#444" },
            }}
          >
            {isMute ? (
              <VolumeUp fontSize="small" />
            ) : (
              <VolumeOff fontSize="small" />
            )}
            <Typography variant="body2" sx={{ ml: 1 }}>
              {isMute ? "Unmute Notifications" : "Mute Notifications"}
            </Typography>
          </MenuItem>

          <MenuItem
            onClick={() => {
              handleDeleteAll();
              handleClose();
            }}
            sx={{ color: "white", "&:hover": { backgroundColor: "#444" } }}
          >
            <Delete fontSize="small" />
            <Typography variant="body2" sx={{ ml: 1 }}>
              Delete Chats
            </Typography>
          </MenuItem>

          <MenuItem
            onClick={() => {
              handleArchiveAll();
              handleClose();
            }}
            sx={{ color: "white", "&:hover": { backgroundColor: "#444" } }}
          >
            <Archive fontSize="small" />
            <Typography variant="body2" sx={{ ml: 1 }}>
              Archive Chats
            </Typography>
          </MenuItem>
        </Popover>
      </div>
    </div>
  );
};

const Chat = () => {
  const [message, setMessage] = useState("");
  const [currentChannel, setCurrentChannel] = useState("Main");
  const [isMute, setIsMute] = useState(true);
  const [suggestions] = useState(["pa", "exc", "help"]);
  const [activeSuggestion, setActiveSuggestion] = useState(-1); // Index of the selected suggestion
  const { sendMessage } = WebSocketClient();
  const navigate = useNavigate();
  const { transcript, listening, resetTranscript } = useSpeechRecognition();
  const scrollableDivRef: any = useRef(null);

  const [openConvos, setOpenConvos] = useState<
    {
      channelId: string;
      _id: string;
    }[]
  >([]);

  useEffect(() => {
    if (suggestions[activeSuggestion]) {
      setMessage(`/${suggestions[activeSuggestion]}: `);
    }
  }, [activeSuggestion]);

  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      if (scrollableDivRef.current) {
        scrollableDivRef.current.scrollTop =
          scrollableDivRef.current.scrollHeight + 10;
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    const user: any = JSON.parse(localStorage.getItem("user") || "");
    if (user) {
      api
        .post(`/chat/channels`, { user })
        .then((res) => {
          setOpenConvos(res.data.channels);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  // get the channel  messages
  useEffect(() => {
    api
      .get(`/chat/messages/${currentChannel}`)
      .then((res) => {
        store.dispatch(setMessages(res.data.messages));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [currentChannel]);

  // Update the message state whenever the transcript changes
  useEffect(() => {
    setMessage(transcript);
  }, [transcript]);
  useEffect(() => {
    // Subscribe to Redux store changes
    const unsubscribe = store.subscribe(async () => {
      const state: any = store.getState();
      // Update local state from Redux state
      setIsMute(state.isMute);
    });

    // Cleanup subscription on component unmount
    return () => {
      unsubscribe();
    };
  }, []); // Empty dependency array ensures this runs only once

  useEffect(() => {
    document.addEventListener("keypress", handleKeyPress);
    return () => {
      document.removeEventListener("keypress", handleKeyPress);
    };
  });

  function parseMessage(str: string): Message {
    const firstSplit = str.indexOf(":");
    const secondSplit = str.indexOf(":", firstSplit + 1);

    const channelId = str.slice(0, firstSplit).trim();
    const sender = str.slice(firstSplit + 1, secondSplit).trim();
    const content = str.slice(secondSplit + 1).trim(); // Everything after the second `:`

    return {
      channelId,
      sender,
      content,
    };
  }
  const send = () => {
    if (message.length === 0) return;
    switch (message.toLowerCase()) {
      case "tasks":
        navigate("/tasks");
        break;
      case "settings":
        navigate("/settings");
        break;
      case "passwords":
        navigate("/managePasswords");
        break;
      case "projects":
        navigate("/projects");
        break;
      case "account":
        navigate("/account");
        break;
      default:
        sendMessage(
          parseMessage(
            `${currentChannel}:${store.getState().username}: ${message}`
          )
        );
        break;
    }
    setMessage("");
    resetTranscript(); // Clear the transcript after sending
  };
  const handleKeyDown = (e: any) => {
    if (e.key === "ArrowDown") {
      setActiveSuggestion((prev) => {
        return prev < suggestions.length - 1 ? prev + 1 : 0;
      });
    } else if (e.key === "ArrowUp") {
      // e.preventDefault(); // Prevent cursor from moving in the input field
      // Move up in the list
      setActiveSuggestion((prev) =>
        prev > 0 ? prev - 1 : suggestions.length - 1
      );
    }
  };

  const handleKeyPress = (e: any) => {
    if (e.key === "Enter") {
      if (
        message.startsWith("/") &&
        message.includes(suggestions[activeSuggestion]) &&
        activeSuggestion >= 0
      ) {
        send();
      } else if (message.startsWith("/")) {
        setMessage(`/${suggestions[activeSuggestion]}: `);
      } else if (!message.startsWith("/")) {
        send();
      }
    }
  };

  const handleChange = (event: any) => {
    setCurrentChannel(event.target.value);
  };

  const handleCreateChannel = () => {
    const today = new Date();
    const user: any = JSON.parse(localStorage.getItem("user") || "");
    if (!user) return;
    api
      .post("/chat/channel/add", {
        userId: user?.id,
        channelId: `channel-${today.getFullYear()}-${today.getMonth()}-${today.getDate()}-${today.getSeconds()}`,
      })
      .then((res) => {
        setOpenConvos(res.data.channels);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
      <ChatSidebar
        currentChannel={currentChannel}
        handleChange={handleChange}
        openConvos={openConvos}
        handleCreateChannel={handleCreateChannel}
      />
      <div ref={scrollableDivRef} className="chat-wrapper">
        <MessageDisplay />
        <div className="input-wrapper">
          <div className="input-container">
            <TextField
              type="text"
              autoFocus
              style={{ width: "100%" }}
              placeholder="Message or Command"
              className="message-input"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              InputProps={{
                style: {
                  caretColor: "white", // Keep the caret visible
                  color: "white", // Hide input text (use overlay instead)
                },
                className: "message-input",
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton
                      className={
                        !listening ? "send-button" : "send-button hover"
                      }
                      onClick={() =>
                        listening
                          ? SpeechRecognition.stopListening()
                          : SpeechRecognition.startListening()
                      }
                    >
                      <Mic color="error" />
                    </IconButton>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton className="send-button" onClick={send}>
                      <SendIcon color="error" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onKeyDown={handleKeyDown}
            />
          </div>
          {message.startsWith("/") && suggestions.length > 0 && (
            <ul className="command-suggestions">
              {suggestions.map((cmd, index) => {
                return (
                  <div
                    key={cmd}
                    className={`suggestion-item ${
                      cmd === suggestions[activeSuggestion] ? "active" : ""
                    }`}
                    onClick={() => {
                      setMessage(`/${cmd} `);
                      setActiveSuggestion(-1);
                    }}
                  >
                    {cmd}
                  </div>
                );
              })}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

const MessageComponent = ({ message }: any) => {
  const formattedContent = message?.content?.replace(/\r/g, "<br/>");
  const handleCopy = () => {
    navigator.clipboard.writeText(message.content);
    toast.success("Message copied to clipboard!");
  };

  return (
    <div className="message-container">
      <span
        className="message"
        dangerouslySetInnerHTML={{ __html: formattedContent }}
      ></span>
      <Tooltip title="Copy">
        <IconButton className="copy-button" onClick={handleCopy}>
          <ContentCopy style={{ fontSize: "0.5em" }} />
          {/* <small>copy</small> */}
        </IconButton>
      </Tooltip>
      <small className="timestamp">{moment().fromNow(message.timestamp)}</small>
    </div>
  );
};

const splitTextIntoChunks = (text: string = "", maxLength = 100) => {
  if (!text.length) return;
  const sentences = text.match(/[^.!?]+[.!?]*/g) || []; // Split by sentences
  const chunks: string[] = [];
  let currentChunk = "";

  sentences.forEach((sentence) => {
    if ((currentChunk + sentence).length <= maxLength) {
      currentChunk += sentence;
    } else {
      chunks.push(currentChunk);
      currentChunk = sentence;
    }
  });

  if (currentChunk) chunks.push(currentChunk);
  return chunks;
};

let voices: SpeechSynthesisVoice[] = [];
const speechSynthesisInitialized = new Promise<void>((resolve) => {
  // Ensure voices are loaded
  const populateVoices = () => {
    voices = window.speechSynthesis.getVoices();
    if (voices.length) {
      resolve();
    }
  };

  // Listen for voices changed event
  window.speechSynthesis.onvoiceschanged = populateVoices;
  populateVoices(); // Initial population attempt
});

const speakText = async (text: string, mute: boolean) => {
  if (mute) return; // Stop processing if muted
  console.time("speaking");

  if ("speechSynthesis" in window) {
    await speechSynthesisInitialized; // Wait until voices are loaded
    const chunks = splitTextIntoChunks(text) || [];

    for (const chunk of chunks) {
      if (mute) break; // Stop processing further if muted

      const utterance = new SpeechSynthesisUtterance(chunk);
      utterance.lang = "en-US";
      window.speechSynthesis.speak(utterance);

      // Wait for each chunk to finish before moving to the next one
      await new Promise<void>((resolve) => {
        utterance.onend = () => resolve();
      });
    }
    cancelSpeech();

    console.timeEnd("speaking");
  } else {
    toast("Text-to-Speech not supported in this browser.");
  }
};

const cancelSpeech = () => {
  if ("speechSynthesis" in window) {
    window.speechSynthesis.cancel();
  }
};

const MessageDisplay = () => {
  const [mute, setMute] = useState(false);
  const messageLog = useSelector((state: any) => state.messages); // Message history
  const username = useSelector((state: any) => state.username); // Current user's username

  useEffect(() => {
    const lastMessage = messageLog?.[messageLog?.length - 1];
    const lastSenderName = lastMessage?.sender;

    // Speak the message only if:
    // 1. It's not muted
    // 2. The sender is not the current user
    if (!mute && lastSenderName !== "guest" && lastSenderName !== username) {
      speakText(lastMessage?.content, mute);
    }
  }, [messageLog, mute, username]);

  useEffect(() => {
    // Subscribe to Redux store changes
    const unsubscribe = store.subscribe(() => {
      const state: any = store.getState();

      if (state.isMute) {
        cancelSpeech(); // Stop ongoing speech synthesis when muted
      }

      setMute(state.isMute); // Update local mute state
    });
    // Cleanup subscription on component unmount
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <div className="display-chat">
      <div className="message-list">
        {messageLog?.length > 0 &&
          messageLog?.map((message: any) => (
            <div key={message.id} className="message-item">
              <span>{message?.sender ?? "Personal Assistant"}</span>
              <MessageComponent message={message} />
            </div>
          ))}
      </div>
    </div>
  );
};

export default Chat;
