import { PayloadAction, createSlice } from "@reduxjs/toolkit";

// Define the shape of the state
interface ChatState {
  channel: string;
  messages: string[];
  isAuthenticated: boolean;
  isAllowGuests: boolean;
  isMute: boolean;
  isConnected: boolean;
  username: string;
}

// Function to load initial state from localStorage
const loadInitialState = (): ChatState => ({
  channel: "Main  ",
  isAllowGuests: false,
  messages: [],
  isMute: false,
  isAuthenticated: false,
  isConnected: !!localStorage.getItem("token"),
  username: localStorage.getItem("username") || "",
});

// Create the slice
const ChatStore = createSlice({
  name: "chat",
  initialState: loadInitialState(),
  reducers: {
    addMessage: (state, action: PayloadAction<any>) => {
      if (state.messages) {
        state.messages.push(action.payload);
      }
    },
    setChannel: (state, action: PayloadAction<string>) => {
      state.channel = action.payload;
    },
    setMessages: (state, action: PayloadAction<any>) => {
      state.messages = action.payload || [];
    },
    setIsAllowedGuests: (state, action: PayloadAction<boolean>) => {
      state.isAllowGuests = action.payload;
    },
    setIsAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
    },
    setUserName: (state, action: PayloadAction<string>) => {
      state.isConnected = !!localStorage.getItem("token");
      localStorage.setItem("username", action.payload);
      state.username = action.payload;
    },
    setIsConnected: (state, action: PayloadAction<boolean>) => {
      state.isConnected = action.payload;
    },
    setMute: (state, action: PayloadAction<boolean>) => {
      state.isMute = action.payload;
    },
  },
});

export const {
  setIsConnected,
  addMessage,
  setUserName,
  setIsAuthenticated,
  setMute,
  setMessages,
  setIsAllowedGuests,
  setChannel,
} = ChatStore.actions;

export default ChatStore;
