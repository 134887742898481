import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../api";
import store from "../../redux";
import {
  setIsAllowedGuests,
  setMute,
  setUserName,
} from "../../redux/ChatStore";
import videoSrc from "../../video/AdobeStock_789998454.mov";
import "./landingPage.scss";

const LandingPage = (props: any) => {
  const navigate = useNavigate();
  const [isGuestsEnabled, setIsGuestsEnabled] = useState(false);

  const handleGuest = async (event: React.MouseEvent<HTMLButtonElement>) => {
    try {
      event.preventDefault();
      const response = await api.post("/auth/guest");
      if (response.status === 200) {
        const { token, user } = response.data;
        localStorage.setItem("token", token);
        localStorage.setItem("user", JSON.stringify(user));
        store.dispatch(setUserName(user.id));
        navigate("/projects");
      } else {
        toast.error("Unable to create guest session. Please try again.");
      }
    } catch (error) {
      console.error("Guest login failed:", error);
      toast.error("An error occurred while logging in as a guest.");
    }
  };

  const handleLogin = () => {
    props.setLanded(true);
    navigate("/login");
  };

  useEffect(() => {
    if (!props.landed) {
      getSettings();
    }
  }, []);

  const getSettings = async () => {
    try {
      const settings = await api.get("/settings/");
      setIsGuestsEnabled(settings.data.allowGuests);
      store.dispatch(setIsAllowedGuests(settings.data.allowGuests));
      store.dispatch(setMute(settings.data.isMute));
    } catch (error) {
      console.error("Error fetching settings:", error);
      toast.error("Unable to load settings. Please try again.");
    }
  };

  return (
    <div className="landing-container" role="main">
      <video className="video-background" autoPlay loop muted>
        <source src={videoSrc} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="robot-eyes-animation" aria-label="Robot Animation">
        <div className="eye"></div>
        <div className="eye"></div>
      </div>
      <div className="welcome-message">
        <h1 className="welcome-text">Hi, I am your personal assistant</h1>
        <p className="subtext">How can I help you?</p>
        <small>Click continue to see my portfolio</small>
      </div>
      <div className="action-buttons">
        <button
          className="action-button login-btn"
          onClick={handleLogin}
          aria-label="Login"
        >
          Login
        </button>
        {isGuestsEnabled && (
          <button
            className="action-button continue-btn"
            onClick={handleGuest}
            aria-label="Continue as Guest"
          >
            Continue as Guest
          </button>
        )}
      </div>
    </div>
  );
};

export default LandingPage;
