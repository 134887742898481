import {
  faCog,
  faProjectDiagram,
  faSignOutAlt,
  faTasks,
  faUnlockAlt,
  faMessage,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import {
  ThemeProvider,
  createTheme,
  makeStyles,
} from "@material-ui/core/styles";
import { Link, useNavigate } from "react-router-dom";
import animeBoy from "../../svg/animeboy.svg";
import store from "../../redux";
import { setUserName } from "../../redux/ChatStore";
import { useEffect, useState } from "react";

const drawerWidth = 240;
const theme = createTheme({
  palette: {
    type: "dark",
  },
});

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#171717",
  },
  toolbar: theme.mixins.toolbar,
  profile: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2),
  },
  profileIcon: {
    fontSize: "2rem",
    marginBottom: theme.spacing(1),
  },
}));

const Sidebar = ({
  sidebarOpen,
  setSidebarOpen,
}: {
  sidebarOpen: boolean;
  setSidebarOpen: Function;
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("username");
    localStorage.removeItem("user");
    store.dispatch(setUserName(""));
    navigate("/login");
  };

  const [isAdmin, setIsAdmin] = useState(false);
  const [isMute, setIsMute] = useState(false);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    setIsAdmin(!!user.isAdmin);
  }, []);

  // subscribe to the store and check if muted
  useEffect(() => {
    // Subscribe to Redux store changes

    const unsubscribe = store.subscribe(() => {
      const state: any = store.getState();
      // Update local state from Redux state
      setIsMute(state.isMute);
    });

    // Cleanup subscription on component unmount
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Drawer
        className={`${classes.drawer} sidebar ${sidebarOpen ? "open" : ""}`} // Adding the className to the Drawer
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <List
          onClick={() => {
            setSidebarOpen(false);
          }}
        >
          <ListItem button component={Link} to="/chat">
            <div className={classes.profile}>
              <img src={animeBoy} alt="profile" width={"100%"} />
              <Typography variant="subtitle1" align="center">
                {localStorage.getItem("token") === "guest"
                  ? "daniel wendemeneh"
                  : "Personal Assistant"}
                {isMute && <span style={{ color: "red" }}> (Muted)</span>}
              </Typography>
            </div>
          </ListItem>
          <Divider />
          {!isAdmin && (
            <ListItem button component={Link} to="/chat">
              <ListItemIcon>
                <FontAwesomeIcon icon={faMessage} />
              </ListItemIcon>
              <ListItemText primary="Pa Chat" />
            </ListItem>
          )}
          <ListItem button component={Link} to="/tasks">
            <ListItemIcon>
              <FontAwesomeIcon icon={faTasks} />
            </ListItemIcon>
            <ListItemText primary="Tasks" />
          </ListItem>
          <ListItem button component={Link} to="/projects">
            <ListItemIcon>
              <FontAwesomeIcon icon={faProjectDiagram} />
            </ListItemIcon>
            <ListItemText primary="Projects" />
          </ListItem>
          {isAdmin && (
            <>
              <ListItem button component={Link} to="/managePasswords">
                <ListItemIcon>
                  <FontAwesomeIcon icon={faUnlockAlt} />
                </ListItemIcon>
                <ListItemText primary="Passwords" />
              </ListItem>
              <ListItem button component={Link} to="/account">
                <ListItemIcon>
                  <FontAwesomeIcon icon={faUser} />
                </ListItemIcon>
                <ListItemText primary="Users" />
              </ListItem>
              <ListItem button component={Link} to="/settings">
                <ListItemIcon>
                  <FontAwesomeIcon icon={faCog} />
                </ListItemIcon>
                <ListItemText primary="Settings" />
              </ListItem>
            </>
          )}
          <ListItem button onClick={handleLogout}>
            <ListItemIcon>
              <FontAwesomeIcon icon={faSignOutAlt} />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </Drawer>
    </ThemeProvider>
  );
};

export default Sidebar;
